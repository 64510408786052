/*
  Jquery Validation using jqBootstrapValidation
  */
$(".sendBox input").on("click", function () {
  if ($(".selectedCheckbox").is(":checked")) {
    $("input,textarea").jqBootstrapValidation({
      preventSubmit: true,
      submitError: function ($form, event, errors) {
        // something to have when submit produces an error ?
        // Not decided if I need it yet
      },
      submitSuccess: function ($form, event) {
        event.preventDefault(); // prevent default submit behaviour
        // get values from FORM
        var firstname = $("input#firstname").val();
        var lastname = $("input#lastname").val();
        var companyname = $("input#companyname").val();
        var phone = $("input#phone").val();
        var email = $("input#email").val();
        var message = $("textarea#message").val();
        var firstName = firstname;
        $.ajax({
          url: "contact_me.php",
          type: "POST",
          data: {
            firstname: firstname,
            lastname: lastname,
            companyname: companyname,
            phone: phone,
            email: email,
            message: message,
          },
          cache: false,
          success: function () {
            // Success message
            $("#success").html("<div class='alert alert-success'>");
            $("#success > .alert-success").html("").append("</button>");
            $("#success > .alert-success").append("Vaše zpráva byla odeslána.");
            $("#success > .alert-success").append("</div>");

            //clear all fields
            $("#contactForm").trigger("reset");
          },
          error: function () {
            // Fail message
            $("#success").html("<div class='alert alert-danger'>");
            $("#success > .alert-danger").html("").append("</button>");
            $("#success > .alert-danger").append(
              "Pozor! omlouváme se, " +
                firstName +
                ", prosím, kontaktujte nás přímo na <a href='mailto:abc@abc.cz'>abc@abc.cz</a>."
            );
            $("#success > .alert-danger").append("</div>");
            //clear all fields
            $("#contactForm").trigger("reset");
          },
        });
      },
      filter: function () {
        return $(this).is(":visible");
      },
    });
  } else {
    $("#success").html("<div class='alert alert-danger'>");
    $("#success > .alert-danger").html("").append("</button>");
    $("#success > .alert-danger").append("Prosím, potvrďte souhlas.");
    $("#success > .alert-danger").append("</div>");
  }

  $('a[data-toggle="tab"]').click(function (e) {
    e.preventDefault();
    $(this).tab("show");
  });
});

/*When clicking on Full hide fail/success boxes */

$("#name").focus(function () {
  $("#success").html("");
});
